import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element)
import './assets/css/reset.css';
import './assets/css/style.css';
//

// import 'lib-flexible/flexible' //rem 转换器
// Vue.use(Element, {
//     size: Cookies.get('size') || 'medium' // set element-ui default size
// })

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
//option && myChart.setOption(option);