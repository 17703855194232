import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'login',
        component: resolve => require(['@/views/login/login.vue'], resolve),

        children: [
            //除了登录注册 路由写在这里
            // {
            //     path: '/index',
            //     name: 'index',
            //     component: resolve => require(['@/views/index/index.vue'], resolve),
            // },
            // {
            //     path: '/nn',
            //     name: 'nn',
            //     component: resolve => require(['@/views/index/nn.vue'], resolve),
            // },
        ]
    },
    {
        path: '/report',
        name: 'report',
        // component: () => import('../views/index.vue'),
        component: resolve => require(['@/views/index/report.vue'], resolve),
    },
    //除了登录注册 路由写在这里
    {
        path: '/index',
        name: 'index',
        component: resolve => require(['@/views/index/index.vue'], resolve),
    },
    {
        path: '/edg',
        name: 'edg',
        component: resolve => require(['@/views/index/edg.vue'], resolve),
    },
    {
        path: '/nn',
        name: 'nn',
        component: resolve => require(['@/views/index/nn.vue'], resolve),
    },
    {
        path: '/form',
        name: 'form',
        component: resolve => require(['@/views/index/form.vue'], resolve),
    },
    // {
    //     path: '/report',
    //     name: 'report',
    //     component: resolve => require(['@/views/index/report.vue'], resolve),
    // },
]

const router = new VueRouter({
    routes
})

export default router
