import request from '@/utils/request'

// 登录方法
export function login(data) {
  return request({
    url: '/member/login',
    method: 'post',
    // noToken: true,
    data: data,
  })
}
// 邮箱登录方法
export function email_login(data) {
  return request({
    url: '/member/auditEmail',
    method: 'post',
    // noToken: true,
    data: data,
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/login/getByToken',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/member/logout',
    method: 'get'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/login/getCode',
    method: 'get',
  })
}
// 获取验证码
export function sendcode(data) {
  return request({
    url: '/auth/sendEmail',
    method: 'get',
    params: data,
  })
}
export function changepass(query) {
  return request({
    url: '/login/password/change',
    method: 'get',
    params: query
  })
}
